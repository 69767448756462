@import "./vars.scss";

section.career {
  max-width: 60rem;
  margin: 0 auto;
  padding-top: 2rem;
  @include splitter-glide;

  .section-header {
    margin-top: 4rem;
  }

  .canvas-container {
    width: 90%;
    margin: 0 auto;
    pointer-events: none;
    position: relative;

    canvas {
      $side-color: rgba(0, 0, 0, .04);
      $side-offset: 2%;

      border-radius: .5rem;
      background: linear-gradient(to left, $side-color 0%, transparent $side-offset, transparent (100% - $side-offset), $side-color 100%);
      box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .15), 0 .1rem .2rem 0 rgba(0, 0, 0, .1);
      transition: width 0.3s ease;
    }

    .pinch-and-zoom {
      $duration: 15s;

      position: absolute;
      bottom: 2.2rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: .7rem;

      width: 1rem;
      height: 1rem;
      position: relative;

      .right-hand {
        left: 50%;
        position: absolute;
        animation: pinchAndZoom $duration ease-in-out forwards;
      }

      .reflect-x {
        right: 50%;
        transform: scaleX(-1);
        position: absolute;
      }

      .left-hand {
        animation: pinchAndZoom $duration ease-in-out forwards;
      }
    }

    &:hover {
      .pinch-and-zoom {
        opacity: 0;
      }
    }
  }

  .scroller {
    pointer-events: all;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .pills-container {
    position: relative;
    @include adaptive-padding;

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1rem;

    @include media(lt-md) {
      flex-direction: column-reverse;
    }

    .ui-hint {
      text-align: right;
      margin: .5rem;

      @include media(lt-md) {
        text-align: center;
      }
    }

    .pills {
      max-width: 30rem;

      a {
        text-decoration: none;
      }
    }
  }

  .timeline-container {
    p.description {
      margin-bottom: .5rem;
    }
  }

  .list-container {
    max-width: 30rem;
    margin: 0 auto;

    h2 {
      font-size: 1.1rem;
    }

    @include media(sm) {
      padding: 0 0.25rem;
      font-size: 0.6rem;
    }

    @include media(md) {
      padding: 0 0.25rem;
      font-size: 0.8rem;
    }

    .timeline-pill {
      display: inline-flex;
      margin-bottom: 1rem;
    }

    .timeline-event {
      overflow: hidden;
      background: rgba(0, 0, 0, 0.03);
      padding: 0.25rem 1rem;
      border-radius: 0.25rem;
      margin-bottom: 0.25rem;
      position: relative;
      transition: height 0.4s ease;

      &:last-child {
        margin-bottom: 0;
      }

      .chevron {
        font-size: 0.8rem;
        position: absolute;
        right: 0.5rem;
        top: 0.4rem;
        opacity: 0.7;
        transform: rotateZ(-90deg);
        transition: transform 0.3s ease;
        text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.3);
      }

      .modal-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.25rem;
        cursor: pointer;
      }

      .modal-content {
        opacity: 1;
        transition: opacity 0.3s linear;
      }

      &.collapsed {
        .modal-content {
          height: 0;
          opacity: 0;
        }

        .chevron {
          transform: rotateZ(0deg);
        }
      }

      &.hidden {
        display: none;
      }

      .event-dates {
        font-size: 0.5rem;
        opacity: 0.5;
      }
    }

    ul.img-section {
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      display: flex;
      flex-direction: row;
      list-style-type: none;

      @include media(lt-md) {
        padding: 0;
      }

      li {
        margin: 0 0.5rem;
        flex-grow: 1;

        @include media(lt-md) {
          margin: 0 0.15rem;
        }
      }

      img {
        margin: 0;
        min-height: 100%;
        vertical-align: bottom;
      }
    }

    img,
    video {
      display: block;
      margin: 0.5rem auto;
      max-width: 100%;
      max-height: 10rem;
      border-radius: 0.2rem;
      box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
    }

    video:active,
    video:focus {
      outline: none;
    }

    p + ul {
      margin-top: -0.25rem;
    }

    ul {
      list-style-type: "- ";
      padding-left: 1rem;
    }

    .twitter-tweet {
      margin: 0 auto;
    }

    iframe {
      display: block;
      margin: 0 auto;
    }

    code {
      font-size: 0.8em;
      line-height: 1em;
      font-weight: bold;
      letter-spacing: -1px;
    }
  }
}

.medium-zoom-overlay,
.medium-zoom-image {
  z-index: 1;
}

.medium-zoom-image {
  transition: all 0.3s ease-in-out;

  &--opened {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }

  &--hidden {
    box-shadow: none;
  }
}