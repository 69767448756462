section.career {
  max-width: 60rem;
  margin: -15vh auto 0;
  padding-top: 15vh;
}

section.career .section-header {
  margin-top: 4rem;
}

section.career .canvas-container {
  pointer-events: none;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

section.career .canvas-container canvas {
  background: linear-gradient(to left, #0000000a 0%, #0000 2% 98%, #0000000a 100%);
  border-radius: .5rem;
  transition: width .3s;
  box-shadow: 0 0 2rem #00000026, 0 .1rem .2rem #0000001a;
}

section.career .canvas-container .pinch-and-zoom {
  width: 1rem;
  height: 1rem;
  font-size: .7rem;
  position: relative;
  bottom: 2.2rem;
  left: 50%;
  transform: translateX(-50%);
}

section.career .canvas-container .pinch-and-zoom .right-hand {
  animation: 15s ease-in-out forwards pinchAndZoom;
  position: absolute;
  left: 50%;
}

section.career .canvas-container .pinch-and-zoom .reflect-x {
  position: absolute;
  right: 50%;
  transform: scaleX(-1);
}

section.career .canvas-container .pinch-and-zoom .left-hand {
  animation: 15s ease-in-out forwards pinchAndZoom;
}

section.career .canvas-container:hover .pinch-and-zoom {
  opacity: 0;
}

section.career .scroller {
  pointer-events: all;
  position: absolute;
  inset: 0;
}

section.career .pills-container {
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 1000px) {
  section.career .pills-container {
    padding: .5rem;
  }
}

@media screen and (width <= 400px) {
  section.career .pills-container {
    padding: .25rem;
  }
}

@media screen and (width <= 1000px) {
  section.career .pills-container {
    flex-direction: column-reverse;
  }
}

section.career .pills-container .ui-hint {
  text-align: right;
  margin: .5rem;
}

@media screen and (width <= 1000px) {
  section.career .pills-container .ui-hint {
    text-align: center;
  }
}

section.career .pills-container .pills {
  max-width: 30rem;
}

section.career .pills-container .pills a {
  text-decoration: none;
}

section.career .timeline-container p.description {
  margin-bottom: .5rem;
}

section.career .list-container {
  max-width: 30rem;
  margin: 0 auto;
}

section.career .list-container h2 {
  font-size: 1.1rem;
}

@media screen and (width <= 400px) {
  section.career .list-container {
    padding: 0 .25rem;
    font-size: .6rem;
  }
}

@media screen and (width >= 400px) and (width <= 1000px) {
  section.career .list-container {
    padding: 0 .25rem;
    font-size: .8rem;
  }
}

section.career .list-container .timeline-pill {
  margin-bottom: 1rem;
  display: inline-flex;
}

section.career .list-container .timeline-event {
  background: #00000008;
  border-radius: .25rem;
  margin-bottom: .25rem;
  padding: .25rem 1rem;
  transition: height .4s;
  position: relative;
  overflow: hidden;
}

section.career .list-container .timeline-event:last-child {
  margin-bottom: 0;
}

section.career .list-container .timeline-event .chevron {
  opacity: .7;
  text-shadow: 0 0 .1rem #0000004d;
  font-size: .8rem;
  transition: transform .3s;
  position: absolute;
  top: .4rem;
  right: .5rem;
  transform: rotateZ(-90deg);
}

section.career .list-container .timeline-event .modal-title {
  cursor: pointer;
  flex-direction: column;
  margin-bottom: .25rem;
  display: flex;
}

section.career .list-container .timeline-event .modal-content {
  opacity: 1;
  transition: opacity .3s linear;
}

section.career .list-container .timeline-event.collapsed .modal-content {
  opacity: 0;
  height: 0;
}

section.career .list-container .timeline-event.collapsed .chevron {
  transform: rotateZ(0);
}

section.career .list-container .timeline-event.hidden {
  display: none;
}

section.career .list-container .timeline-event .event-dates {
  opacity: .5;
  font-size: .5rem;
}

section.career .list-container ul.img-section {
  flex-direction: row;
  margin: .5rem 0;
  padding: 0 .5rem;
  list-style-type: none;
  display: flex;
}

@media screen and (width <= 1000px) {
  section.career .list-container ul.img-section {
    padding: 0;
  }
}

section.career .list-container ul.img-section li {
  flex-grow: 1;
  margin: 0 .5rem;
}

@media screen and (width <= 1000px) {
  section.career .list-container ul.img-section li {
    margin: 0 .15rem;
  }
}

section.career .list-container ul.img-section img {
  vertical-align: bottom;
  min-height: 100%;
  margin: 0;
}

section.career .list-container img, section.career .list-container video {
  border-radius: .2rem;
  max-width: 100%;
  max-height: 10rem;
  margin: .5rem auto;
  display: block;
  box-shadow: 0 .1rem .2rem #0003;
}

section.career .list-container video:active, section.career .list-container video:focus {
  outline: none;
}

section.career .list-container p + ul {
  margin-top: -.25rem;
}

section.career .list-container ul {
  padding-left: 1rem;
  list-style-type: "- ";
}

section.career .list-container .twitter-tweet {
  margin: 0 auto;
}

section.career .list-container iframe {
  margin: 0 auto;
  display: block;
}

section.career .list-container code {
  letter-spacing: -1px;
  font-size: .8em;
  font-weight: bold;
  line-height: 1em;
}

.medium-zoom-overlay, .medium-zoom-image {
  z-index: 1;
}

.medium-zoom-image {
  transition: all .3s ease-in-out;
}

.medium-zoom-image--opened {
  box-shadow: 0 .5rem 1rem #00000080;
}

.medium-zoom-image--hidden {
  box-shadow: none;
}
/*# sourceMappingURL=index.be3771bb.css.map */
